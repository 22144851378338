// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  API_URL: 'https://devapi.vsptwinegroup.com',
  GCP_CLIENT_ID: '281809254013-6jta592ds278aovsu8vh4bkvgguln31c.apps.googleusercontent.com',
  LS_ENCRYPTION: '3c0d2641-7a05-40b6-8a9b-bea623a3235e',
  GUEST_ME: 'bk4k6ep0s1b4n9947',
  SW_VERSION: '2.2.0',
  PAGINATION_SIZE: 5
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
